import * as Sentry from "@sentry/nuxt";

Sentry.init({
    environment: import.meta.env.VITE_ENV_NAME || 'localhost',
    debug: false,
    dsn: 'https://66ac5f865e724a9a834f7bc536b63363@o4504580912119808.ingest.sentry.io/4504632174837760',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [import.meta.env.VITE_BASE_URL.slice(8), /^\//],
    tracesSampleRate: 0.25,
  })
